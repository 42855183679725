import React from 'react'
import Layout from '../../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import * as styles from '../../pages/e-books.module.css'

const RespectPage = () => {
  return (
    <Layout>
      <section className={`${styles.jumbo} jumbo h-52 sm:h-72`}>
        <div className="container">
          <h1 className="text-5xl sm:text-8xl text-red-800">7 Rules for Respect</h1>
        </div>
      </section>
      <section className="content pt-16">
        <div className="container mx-auto px-4 !max-w-[100%]">
          <div className="flex flex-col md:flex-row gap-12">
            <div className="w-full md:w-1/4">
              <StaticImage 
                src="../../images/respect-cover.jpg"
                alt="7 Rules for Commanding Respect Book Cover"
                className="w-full rounded-lg"
              />
            </div>
            <div className="w-full md:w-1/2">
              <div className="flex flex-col md:flex-row justify-between items-center gap-4 mb-4">
                <h2 className="text-3xl font-bold text-gray-900 !mt-0">7 Rules for Commanding Respect in a Relationship</h2>
                <a 
                  href="https://marciancx.gumroad.com/l/respect"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-red-800 text-white px-4 py-4 rounded-lg hover:bg-red-900 transition-colors whitespace-nowrap"
                >
                  Get your FREE copy!
                </a>
              </div>
              <div className="space-y-4">
                <p className="text-gray-700 text-base">
                  A woman cannot love a man she does not respect. If you find yourself being ignored, dismissed, or constantly tested, it's because you allowed it.
                </p>
                <p className="text-gray-700 text-base">
                  This short, no-nonsense guide breaks down the seven key rules every man must follow to command respect effortlessly—without arguing, chasing, or seeking approval.
                </p>
                <p className="text-gray-700 text-base">
                  Inside, you'll learn:<br />
                  • Why a man's mission must always come first<br />
                  • The one mistake that kills respect instantly<br />
                  • How to set boundaries and enforce them without hesitation<br />
                  • Why women only follow men who lead with certainty<br />
                  • The secret to earning respect without saying a word
                </p>
                <p className="text-gray-700 text-base mb-8">
                  Respect is not given—it is earned. Follow these rules, and you will never have to demand it.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default RespectPage 